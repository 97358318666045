import { reactive, toRefs, defineAsyncComponent, computed, nextTick, watch } from 'vue';
import { useStore } from 'vuex';
import { useDebounce } from '@/hooks/core/useDebounce';
import API from "@/api/authority/index";
import { ElMessageBox } from "element-plus";
import { useMessage } from '@/hooks/web/useMessage';
import { Search, Plus } from "@element-plus/icons-vue";
import { formatDate } from '@/utils/formatTime';
export default {
  name: "Account",
  components: {
    TreeSelect: defineAsyncComponent(() => import('@/components/TreeSelect/TreeSelect.vue')),
    BasicTemplate: defineAsyncComponent(() => import('@/components/BasicTemplateTablePage/index.vue')),
    AddAccount: defineAsyncComponent(() => import('./components/AddAccount.vue'))
  },
  setup() {
    const store = useStore();
    const message = useMessage();
    const refData = reactive({
      addAccountShow: false,
      loading: false,
      total: 0,
      current: 1,
      pageSize: 100,
      searchForm: {
        mobile: '',
        deptId: null,
        username: ''
      },
      tableData: [],
      tableColumns: [{
        title: '序号',
        align: 'center',
        key: 'index',
        width: 60,
        slots: {
          customRender: 'index'
        },
        visible: true
      }, {
        title: '账号名称',
        align: 'center',
        key: 'username',
        visible: true
      }, {
        title: '昵称',
        align: 'center',
        key: 'nickname',
        visible: true
      }, {
        title: '所属组织',
        align: 'center',
        key: 'deptName',
        visible: true
      },
      // {
      //   title: '手机号码',
      //   align: 'center',
      //   key: 'name',
      //   visible: true,
      // },
      {
        title: '创建时间',
        align: 'center',
        key: 'createTime',
        visible: true
      }, {
        fixed: 'right',
        title: '操作',
        align: 'center',
        key: 'opereat',
        width: 200,
        slots: {
          customRender: 'opereat'
        },
        visible: true
      }],
      activeAccountId: null,
      activeOrg: {
        orgId: null,
        orgName: '',
        activeValue: null
      }
    });
    const id = computed(() => store.state.treeData.length ? store.state.treeData[0][0].id : null);
    const userInfo = computed(() => store.state.userInfo);
    const onSearch = async () => {
      refData.loading = true;
      const params = {
        deptId: refData.searchForm.deptId,
        mobile: refData.searchForm.mobile,
        pageSize: refData.pageSize,
        pageNo: refData.current,
        nickname: '',
        username: refData.searchForm.username
      };
      try {
        const {
          data,
          msg,
          code
        } = await API.getUserTable(params);
        refData.total = data.total;
        if (data.list) {
          refData.tableData = data.list.map(item => {
            item.createTime = formatDate(item.createTime);
            return item;
          });
        }
        refData.tableData = data.list.filter(item => item.id != userInfo.value.id);
        refData.loading = false;
      } catch (error) {
        refData.loading = false;
      }
    };
    watch(() => id.value, val => {
      nextTick(() => {
        val && (refData.searchForm.deptId = val, debounceOnSearch());
      });
    });
    nextTick(() => {
      refData.searchForm.deptId = store.state.treeData.length ? store.state.treeData[0][0].id : null, debounceOnSearch();
    });
    const [debounceOnSearch] = useDebounce(onSearch, 200);
    const currentChange = val => {
      refData.current = val;
      debounceOnSearch();
    };
    const pageSizeChange = val => {
      refData.pageSize = val;
      debounceOnSearch();
    };
    //修改用户
    const handleEdit = async id => {
      refData.activeAccountId = id;
      refData.addAccountShow = true;
    };
    //删除用户
    const handleDel = async (id, name) => {
      if (id == userInfo.value.userId) {
        message.warning('无权限删除自己账号');
        return false;
      }
      ElMessageBox.confirm(`是否确认删除“${name}”该用户`, '提示', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        const {
          code,
          msg
        } = await API.delUser({
          id
        });
        code == 0 && debounceOnSearch();
        message[code == 0 ? 'success' : 'warning'](code == 0 ? '删除成功' : msg);
      }).catch(() => {
        // ElMessage.info("已取消退出");
      });
    };
    //点击组织树
    const handleClick = data => {
      refData.searchForm.deptId = data.id;
      refData.activeOrg.orgId = data.id;
      refData.activeOrg.orgName = data.name;
      refData.activeOrg.activeValue = data.typeId;
      debounceOnSearch();
    };
    //重置密码
    const handleResetPassword = async (userId, name) => {
      ElMessageBox.confirm(`是否确认重置“${name}”该用户密码`, '提示', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        const {
          code,
          msg
        } = await API.resetPassword({
          id: userId,
          password: "A123456"
        });
        if (code == 0) {
          ElMessageBox.confirm(`重置密码成功，默认密码为：A123456`, '提示', {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "success"
          }).then(async () => {
            debounceOnSearch();
          }).catch(() => {
            // ElMessage.info("已取消退出");
          });
        } else {
          message.warning(msg);
        }
      }).catch(() => {
        // ElMessage.info("已取消退出");
      });
    };
    // 初始化函数调用
    return {
      ...toRefs(refData),
      currentChange,
      pageSizeChange,
      debounceOnSearch,
      handleClick,
      handleDel,
      handleEdit,
      userInfo,
      handleResetPassword,
      Search,
      Plus
    };
  }
};